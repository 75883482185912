import { createTheme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Logout } from './components/Logout';
import { AccountlessPage } from './pages/AccountlessPage';
import { AccountSelector } from './pages/AccountSelectorPage';
import { ChangePasswordForm } from './pages/ChangePasswordPage';
import { ForgotPasswordForm } from './pages/ForgotPasswordPage';
import { ForgotSSOForm } from './pages/ForgotSsoPage';
import { LoginForm } from './pages/LoginPage';
import { SignUpForm } from './pages/SignupPage';
import './styles.css';

declare global {
  interface Window {
    buildId: string; //set in the index.html
    defaultPublicClientId: string; //set in th index.html
    defaultUserPoolId: string; //set in the index.html
    appBaseUrl: string; //set in th index.html
    defaultAppRedirect: string;
  }
}
let faviconImage: string;
if (window.location.origin.endsWith(`cisco.com`)) {
  faviconImage = `${window.appBaseUrl}/static/jupiterone/images/SCI_favicon2x.png`;
  document.title = `SCI Login`;
} else {
  faviconImage = `${window.appBaseUrl}/static/jupiterone/images/jupiterone-logo-on-light-background.svg`;
}

const theme = createTheme({
  typography: {
    fontFamily: `Lato`,
  },
});

export function App() {
  //Needed to get the favicon to load, It is not in the index.html because we use a CDN
  //And vite would not allow us to bundle it.
  const link = document.createElement('link');
  link.href = faviconImage;
  link.rel = 'icon';
  document.head.appendChild(link);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/account-selector" component={AccountSelector} />
          <Route exact path="/forgot-password" component={ForgotPasswordForm} />
          <Route exact path="/forgot-sso" component={ForgotSSOForm} />
          <Route exact path="/change-password" component={ChangePasswordForm} />
          <Route exact path="/sign-up" component={SignUpForm} />
          <Route exact path="/welcome" component={AccountlessPage} />
          <Route path="*" component={LoginForm} />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}
