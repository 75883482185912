import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GoogleButtonImg from '../../../public/Google__G__Logo.png';
import LinksButtonImg from '../../../public/icons/links.svg';
import { Page } from '../../components/Page';
import { EmailLogin } from './EmailLogin';
import { SignInActionButton } from './SignInActionButton';

const TERMS_URL = 'https://jupiterone.com/terms/';
const PRIVACY_POLICY_URL = 'https://jupiterone.com/privacy-policy/';

const useStyles = makeStyles(() => ({
  agreementMessage: {
    marginTop: '1rem',
    textAlign: 'center',
    fontSize: '0.9rem',
  },
}));

export function LoginForm() {
  const classes = useStyles();
  // Changes the url of the image to point to the CDN url
  const googleButtonUrl = GoogleButtonImg.replace('BUILD_ID', window.buildId);
  const linksButtonURL = LinksButtonImg.replace('BUILD_ID', window.buildId);

  const history = useHistory();

  const handleGoogleLogin = () => {
    document.location.replace(
      `/auth/google/authorize${window.location.search}`,
    );
  };

  const handleGetLinksToAccounts = () => {
    history.push('/forgot-sso');
  };

  return (
    <Page
      title="Sign In"
      rightColumn={
        <>
          <SignInActionButton
            label="Sign in with Google"
            icon={googleButtonUrl}
            onClick={handleGoogleLogin}
          />
          <SignInActionButton
            label="Get links to your accounts"
            icon={linksButtonURL}
            onClick={handleGetLinksToAccounts}
          />
        </>
      }
    >
      <EmailLogin />
      <Typography className={classes.agreementMessage}>
        By logging in you are agreeing to JupiterOne&apos;s <br />
        <a href={TERMS_URL} target="_blank" rel="noreferrer">
          Terms and Conditions
        </a>{' '}
        &{' '}
        <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </Typography>
    </Page>
  );
}
